import {uniq} from "lodash";
/**
 * get ids of patients and practitioners from operations (real, ki, customer, search)
 * @param {Array<{role: {code: String, display: String}, practitionerIds: Array<String>}>} infoList
 * @return {Object} {practitionerIds, patientIds}
 */
const getPersonIdsFromInfo = (infoList) => {
    const practitionerIds = [];

    for (const info of infoList) {
        if (info.practitionerIds?.length) {
            practitionerIds.push(...info.practitionerIds);
        }
    }

    return uniq(practitionerIds);
};

export default getPersonIdsFromInfo;
