/**
 * @fileoverview API for a room and its availabilities
 */

import {omitBy} from "lodash";

import axios from "../../middleware/axios.middelware";

const URL = "/orchestrator/locations";

/**
 * create a blocking date for a room
 * @param {String} id               the internal room id
 * @param {RoomBlockerSlot} data    the new blocker data
 * @param {String} organizationId   organizationId
 * @return {Promise}
 */
function createRoomBlockingDate(id, {startDate, endDate, reason, interval}, organizationId) {
    const data = omitBy(
        {
            startDate,
            endDate,
            reason: reason.length > 0 ? reason : undefined,
            interval,
            organizationId
        },
        (value) => value === undefined
    );

    return axios.post(`${URL}/${encodeURI(id)}/availabilities`, data);
}

/**
 * update a blocking date of a room
 * @param {String} id               the internal room id
 * @param {String} blockerId        the internal blocking date id
 * @param {RoomBlockerSlot} data    the updated data
 * @param {String} organizationId   organizationId
 * @return {Promise}
 */
function updateRoomBlockingDate(
    id,
    blockerId,
    {startDate, endDate, reason, interval, hourStartLocalTime, hourEndLocalTime, seriesId},
    organizationId
) {
    const data = omitBy(
        {
            startDate,
            endDate,
            reason: reason.length > 0 ? reason : undefined,
            interval,
            hourStartLocalTime,
            hourEndLocalTime,
            organizationId,
            seriesId
        },
        (value) => value === undefined
    );
    return axios.patch(`${URL}/${encodeURI(id)}/availabilities/${encodeURI(blockerId)}`, data);
}

/**
 * delete a blocking date of a room
 * @param {String} id               the internal room id
 * @param {String} blockerId        the internal blocking date id
 * @param {String} organizationId
 * @param {Boolean} deleteSeries    delete the complete series (if any) or just the single entry?
 * @return {Promise}
 */
function deleteRoomBlockingDate(id, blockerId, organizationId, deleteSeries = false) {
    const data = {
        series: deleteSeries ? "true" : "false",
        organizationId
    };
    return axios.delete(`${URL}/${encodeURI(id)}/availabilities/${encodeURI(blockerId)}`, {data});
}

/**
 * validate an availability slot of an employee without changing the data
 * @param {number} organizationId
 * @param {String} roomId
 * @param {Object} slot     existing slot
 * @param {Object} data
 * @return {Promise}
 */
function validateRoomBlocker(organizationId, roomId, slot, data) {
    const params = {
        organizationId,
        type: "absence",
        blockerId: slot.blockerId,
        seriesId: slot.seriesId,
        ...data
    };
    return axios.get(`${URL}/${roomId}/availability/check`, {params});
}

export {createRoomBlockingDate, deleteRoomBlockingDate, updateRoomBlockingDate, validateRoomBlocker};
