/**
 * @fileoverview redux reducer for room blocker
 */

import {cloneDeep} from "lodash";

import {createReducer} from "../../redux/utils/reducer";
import STATUS from "../../redux/utils/status";
import ActionTypes from "./room_planner_details_action_types";

// validation results for added blocker will be directly set with id as a key
// [id]: {preCalcDates, conflicts} if a slot is new, slotId will be the newSlotIndex.

const initialState = {
    conflicts: {},
    status: STATUS.IDLE,
    error: null,
    added: {}, // added blocker, key: newSlotIndex, value: slotId, ex. {0: "slotId"}
    deleted: [], // used for deleted slots
    patched: {} // patched series blocker (blockerId will be changed if updated, so keep original vs new blockerId mapping)
};

const handleClearConflicts = (state) => ({
    ...state,
    conflicts: {},
    added: {},
    deleted: []
});

const handleDeleteRequest = (state) => ({
    ...state,
    status: STATUS.PENDING,
    error: null
});

const handleDeleteSuccess = (state, {id}) => {
    // add in deleted array
    const deleted = cloneDeep(state.deleted);
    deleted.push(id);

    return {
        ...state,
        status: STATUS.RESOLVED,
        error: null,
        deleted
    };
};

const handleDeleteFailure = (state, {error}) => ({
    ...state,
    status: STATUS.REJECTED,
    error
});

const handleSaveRequest = (state) => ({
    ...state,
    status: STATUS.PENDING,
    error: null
});

const handleSaveSuccess = (state, {createdAt, newId}) => {
    const added = cloneDeep(state.added);
    if (createdAt && newId) {
        // only set if the slot is added
        added[createdAt] = newId;
    }
    return {
        ...state,
        status: STATUS.RESOLVED,
        error: null,
        added
    };
};

const handleSaveFailure = (state, {error}) => ({
    ...state,
    status: STATUS.REJECTED,
    error
});

const handleUpdateFailure = (state, {error}) => ({
    ...state,
    status: STATUS.REJECTED,
    error
});

const handleUpdateRequest = (state) => ({
    ...state,
    status: STATUS.PENDING,
    error: null
});

const handleUpdateSuccess = (state, {blockerId, patchedBlockerId}) => {
    const patched = cloneDeep(state.patched);
    if (blockerId && patchedBlockerId) {
        // only set if the slot is added
        patched[blockerId] = patchedBlockerId;
    }

    return {
        ...state,
        status: STATUS.RESOLVED,
        error: null,
        patched
    };
};

const handleValidateRequest = (state) => ({
    ...state,
    conflicts: {}
});

const handleValidateSuccess = (state, {conflicts, id}) => ({
    ...state,
    [id]: conflicts
});

const handleValidateFailure = (state, {conflicts}) => ({
    ...state,
    conflicts
});

const handlers = {
    [ActionTypes.CLEAR_CONFLICTS]: handleClearConflicts,

    [ActionTypes.CREATE_REQUEST]: handleSaveRequest,
    [ActionTypes.CREATE_SUCCESS]: handleSaveSuccess,
    [ActionTypes.CREATE_FAILURE]: handleSaveFailure,

    [ActionTypes.UPDATE_REQUEST]: handleUpdateRequest,
    [ActionTypes.UPDATE_SUCCESS]: handleUpdateSuccess,
    [ActionTypes.UPDATE_FAILURE]: handleUpdateFailure,

    [ActionTypes.DELETE_REQUEST]: handleDeleteRequest,
    [ActionTypes.DELETE_SUCCESS]: handleDeleteSuccess,
    [ActionTypes.DELETE_FAILURE]: handleDeleteFailure,

    [ActionTypes.VALIDATE_REQUEST]: handleValidateRequest,
    [ActionTypes.VALIDATE_SUCCESS]: handleValidateSuccess,
    [ActionTypes.VALIDATE_FAILURE]: handleValidateFailure
};

export default createReducer(initialState, handlers);
